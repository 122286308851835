import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Feature from "./Pages/Features";
import PrivacyPolicy from "./Pages/SupportPage/PrivacyPolicy";
import RefundPolicy from "./Pages/SupportPage/RefundPolicy";
import ContactUs from "./Pages/SupportPage/ContactUs";
import Solutions from "./Pages/Solutions";
import PageNotFound from "./Pages/SupportPage/PageNotFound";
import TermsAndConditions from "./Pages/SupportPage/TermsAndCondition";

export const RouteNavigation = {
  home: "/",
  about: "/about",
  feature: "/feature",
  privacy: "/privacypolicy",
  refund: "/RefundPolicy",
  contact: "/contact-us",
  solution: "/solutions/:id",
  termsAndConditions: "/terms-and-conditions",
  pagenotfound: "*",
};

const GenericRoutes = () => {
  return (
    <Routes>
      <Route path={RouteNavigation.home} element={<Home />} />
      <Route path={RouteNavigation.about} element={<AboutUs />} />
      <Route path={RouteNavigation.feature} element={<Feature />} />
      <Route path={RouteNavigation.privacy} element={<PrivacyPolicy />} />
      <Route path={RouteNavigation.refund} element={<RefundPolicy />} />
      <Route path={RouteNavigation.contact} element={<ContactUs />} />
      <Route path={RouteNavigation.solution} element={<Solutions />} />
      <Route
        path={RouteNavigation.termsAndConditions}
        element={<TermsAndConditions />}
      />
      <Route path={RouteNavigation.pagenotfound} element={<PageNotFound />} />
    </Routes>
  );
};
export default GenericRoutes;
