import axios from "axios";

axios.defaults.baseURL = "https://xeniamailsender.onrender.com/";

export const emailService = async (data) => {
  const emailServiceURL = "xeniamobi/send-email";
  try {
    const response = await axios.post(emailServiceURL, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const GetAllCountry = () => {
  const getAllCountryUrl = "/getAllCountries";
  try {
    const response = axios.get(getAllCountryUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const GetStateByCountry = (countryName) => {
  const getStateByCountryUrl = `/getStateByCountry/${countryName}`;
  try {
    const response = axios.get(getStateByCountryUrl);
    return response;
  } catch (error) {
    return error;
  }
};
