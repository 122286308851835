import classNames from "classnames";
import { useLocation } from "react-router-dom";
import "./Header.scss";
import { useState, useEffect } from "react";
import { LinkLocation } from "./LinkLocation";
import { RouteNavigation } from "../Routes";
// import { Fragment } from "react";
// import { Menu, Transition } from "@headlessui/react";
import LogoWhite from "../assets/logo_white.png";
import Logo from "../assets/logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { IoRestaurantOutline } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { MdOutlineShoppingBag } from "react-icons/md";
import { BsShopWindow } from "react-icons/bs";
/* import { BiDrink } from "react-icons/bi";
import { LuCakeSlice } from "react-icons/lu"; */
import FuelOutlet from "../assets/gasoline.png";
import Bakery from "../assets/bakery.svg";
import salon from "../assets/salon-icon.svg";

const Header = () => {
  // Listen the scroll event and save to a variable
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY > 100);
      });
    };
  }, [scroll]);

  // useLocation to fetch current Location and class changes based on path
  var pathArray = window.location.hash.split("/");
  const location = useLocation();
  const HeaderStyling =
    location.pathname === "/" && scroll === false
      ? "home-header-style"
      : "header-style";
  const DynamicHeaderLogo =
    location.pathname === "/" && scroll === false ? LogoWhite : Logo;
  const MenuButtonStyling =
    location.pathname === "/" && scroll === false
      ? "home-menu-button"
      : "other-tab-menu-button";

  // Header position class change when scroll event occur
  let stickyHeader;
  if (location.pathname === "/" && scroll === false) {
    stickyHeader = "absolute";
  } else {
    stickyHeader = "sticky top-0 z-10 animate-page-header";
  }

  //Active Link Styling
  const homeWhiteActiveStyle = classNames({
    "home-active-tab": location.pathname === "/",
  });
  const homeBlackActiveStyle = classNames({
    "other-active-tab": location.pathname === "/",
  });
  const HomeActiveLinkStyle =
    scroll === false ? homeWhiteActiveStyle : homeBlackActiveStyle;
  const featureActiveStyle = classNames({
    "other-active-tab": pathArray[1] === "feature",
  });
  const ContactActiveLinkStyle = classNames({
    "other-active-tab": pathArray[1] === "contact-us",
  });

  // Mobile view menu button state
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSolutionMenu, setShowSolutionMenu] = useState(false);

  //Remove Scroll of the body based on Mobile view menu condition
  if (isMenuOpen === true) {
    document.body.classList.add("stop-scrolling");
  } else {
    document.body.classList.remove("stop-scrolling");
  }

  return (
    <header className={`${HeaderStyling} ${stickyHeader} w-full select-none`}>
      <div className="relative flex items-center justify-between">
        <div className="w-[200px] pl-4 sm:pl-16 py-[5px] sm:py-5 ">
          <LinkLocation to={RouteNavigation.home}>
            <img
              src={`${DynamicHeaderLogo}`}
              className="w-[120px] sm:w-auto"
              alt="logo"
            />
          </LinkLocation>
        </div>
        <ul className="hidden lg:flex items-center space-x-8 xl:space-x-16 pl-16 xl:px-16 pr-4 sm:pr-16 py-[5px] sm:py-5 font-monteserrat text-base">
          <li>
            <LinkLocation
              to={RouteNavigation.home}
              className="font-medium tracking-wide"
            >
              <div className={`${HomeActiveLinkStyle} opacity-70`}>HOME</div>
            </LinkLocation>
          </li>
          <li>
            <div className="relative inline-block text-left">
              <div className="group items-center">
                <button
                  type="button"
                  className="inline-flex justify-center items-center w-full px-4 py-2 opacity-70"
                >
                  SOLUTIONS
                  <IoIosArrowDown className="group-hover:rotate-180 transition duration-300 transform-all ml-2" />
                </button>

                <div className="absolute left-0 w-56 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">
                  <div className="pb-4">
                    <LinkLocation
                      to={"/solutions/Restaurants-Software-Solutions"}
                      className="flex gap-2 py-2 text-base text-gray-700 underline-animation"
                    >
                      <IoRestaurantOutline /> Restaurant
                    </LinkLocation>
                    <LinkLocation
                      to={"/solutions/Bakery-Software-Solutions"}
                      className="flex gap-2 py-2 text-base text-gray-700 underline-animation"
                    >
                      <img className="w-4" src={Bakery} alt="bakery" />
                      Bakery
                    </LinkLocation>
                    <LinkLocation
                      to={"/solutions/Van-Sales-Solutions"}
                      className="flex gap-2 py-2 text-base text-gray-700 underline-animation"
                    >
                      <TbTruckDelivery />
                      Van Sales
                    </LinkLocation>
                    <LinkLocation
                      to={"/solutions/Salons-Solutions"}
                      className="flex gap-2 py-2 text-base text-gray-700 underline-animation"
                    >
                      <img className="w-4" src={salon} alt="salon" />
                      Salon
                    </LinkLocation>
                    <LinkLocation
                      to={"/solutions/Fuel-Outlets-Solutions"}
                      className="flex gap-2 py-2 text-base text-gray-700 underline-animation"
                    >
                      <img className="w-4" src={FuelOutlet} alt="gasoline" />
                      Fuel Outlet
                    </LinkLocation>
                    <LinkLocation
                      to={"/solutions/Vegetable-Shops-Solutions"}
                      className="flex gap-2 py-2 text-base text-gray-700 underline-animation"
                    >
                      <BsShopWindow />
                      Vegetable Shop
                    </LinkLocation>
                    <LinkLocation
                      to={"/solutions/Retail-Shops-Solutions"}
                      className="flex gap-2 py-2 text-base text-gray-700 underline-animation"
                    >
                      <MdOutlineShoppingBag />
                      Retail Shop
                    </LinkLocation>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <LinkLocation
              to={RouteNavigation.feature}
              className="font-medium tracking-wide"
            >
              <div className={`${featureActiveStyle} opacity-70`}>FEATURES</div>
            </LinkLocation>
          </li>
          <li>
            <LinkLocation
              to={RouteNavigation.about}
              className="font-medium tracking-wide opacity-70"
            >
              ABOUT
            </LinkLocation>
          </li>
          <li>
            <LinkLocation
              to={RouteNavigation.contact}
              className="font-medium tracking-wide"
            >
              <div className={`${ContactActiveLinkStyle} opacity-70`}>
                CONTACT US
              </div>
            </LinkLocation>
          </li>
        </ul>
        <div className="lg:hidden">
          <button
            className={`${MenuButtonStyling} p-2 mr-2`}
            onClick={() => setIsMenuOpen(true)}
          >
            <RxHamburgerMenu size={"30px"} />
          </button>
          {isMenuOpen && (
            <div
              className="absolute top-0 left-0 w-full z-10 duration-0"
              transition-style="in:custom:circle-swoop"
              // transition-duration:"0.5s"
            >
              <div className="mobile-menu h-[110vh]">
                <div className="flex items-center justify-between mb-4 px-8">
                  <div className="w-[150px] sm:w-[200px]">
                    <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                      <LinkLocation to={RouteNavigation.home}>
                        <img
                          src={LogoWhite}
                          alt="logo"
                          className="w-[100px] sm:w-auto"
                          onClick={() => setIsMenuOpen(false)}
                        />
                      </LinkLocation>
                    </span>
                  </div>
                  <div className="self-end">
                    <button
                      className="mr-5 text-white"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <AiOutlineClose size={"20px"} />
                    </button>
                  </div>
                </div>
                <nav>
                  <ul
                    id="list"
                    className="space-y-4 font-monteserrat text-center text-white"
                  >
                    <li>
                      <LinkLocation
                        to={RouteNavigation.home}
                        className="font-medium tracking-wide"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        HOME
                      </LinkLocation>
                    </li>
                    <li>
                      <ul>
                        <li>
                          <div
                            className="flex justify-center "
                            onClick={() =>
                              setShowSolutionMenu(!showSolutionMenu)
                            }
                          >
                            <span className="cursor-pointer">SOLUTIONS</span>
                            <span className="self-center">
                              <svg
                                className={`fill-current h-4 w-4 transition-all duration-700 cursor-pointer ${
                                  showSolutionMenu ? "rotate-180" : "rotate-0"
                                }`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </span>
                          </div>
                          <div
                            className={`px-6 pt-0 overflow-hidden transition-[max-height] duration-500 ease-in bg-white text-black w-[200px] m-auto rounded-lg solution-mobile-dropdown ${
                              showSolutionMenu ? "max-h-[500px]" : "max-h-0"
                            }`}
                          >
                            <ul className="rounded-xl leading-9">
                              <li className="hover:bg-gray-100 rounded-t-xl">
                                <LinkLocation
                                  to={
                                    "/solutions/Restaurants-Software-Solutions"
                                  }
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Restaurant
                                </LinkLocation>
                              </li>
                              <li className="hover:bg-gray-100 rounded-t-xl">
                                <LinkLocation
                                  to={"/solutions/Bakery-Software-Solutions"}
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Bakery
                                </LinkLocation>
                              </li>
                              <li className="hover:bg-gray-100 rounded-t-xl">
                                <LinkLocation
                                  to={"/solutions/Van-Sales-Solutions"}
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Van Sales
                                </LinkLocation>
                              </li>
                              <li className="hover:bg-gray-100 rounded-t-xl">
                                <LinkLocation
                                  to={"/solutions/Salons-Solutions"}
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Salon
                                </LinkLocation>
                              </li>
                              <li className="hover:bg-gray-100 rounded-t-xl">
                                <LinkLocation
                                  to={"/solutions/Fuel-Outlets-Solutions"}
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Fuel Outlet
                                </LinkLocation>
                              </li>
                              <li className="hover:bg-gray-100 rounded-t-xl">
                                <LinkLocation
                                  to={"/solutions/Vegetable-Shops-Solutions"}
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Vegetable Shop
                                </LinkLocation>
                              </li>
                              <li className="hover:bg-gray-100 rounded-t-xl">
                                <LinkLocation
                                  to={"/solutions/Retail-Shops-Solutions"}
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Retail Shop
                                </LinkLocation>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <LinkLocation
                        to={RouteNavigation.feature}
                        className="font-medium tracking-wide"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        FEATURES
                      </LinkLocation>
                    </li>
                    <li>
                      <LinkLocation
                        to={RouteNavigation.about}
                        className="font-medium tracking-wide"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        ABOUT
                      </LinkLocation>
                    </li>
                    <li>
                      <LinkLocation
                        to={RouteNavigation.contact}
                        className="font-medium tracking-wide"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        CONTACT US
                      </LinkLocation>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
