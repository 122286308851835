import "./Home_custom_style.scss";
import HomeCarousel from "./HomeCarousel";
import Restaurant from "../../assets/services/restaurant-small.webp";
import Restaurant2 from "../../assets/services/restaurant-large.webp";
import Bakkery from "../../assets/services/bakkery.webp";
import VanSale from "../../assets/services/van_sale.webp";
import ServiceData from "../../JSON/Services.json";
import ScrollAnimation from "react-animate-on-scroll";

export const HomeContent = () => {
  const serviceDetails = ServiceData.map((service) => {
    return (
      <div
        className="select-none relative overflow-hidden m-[2px]"
        key={service.id}
      >
        <img
          src={service.img}
          alt="service_image"
          className="rounded-lg w-full transition duration-300 ease-in-out hover:scale-110"
        />
        <h1 className="font-Gotham-bold absolute text-white bottom-0 p-2 font-bold">
          {service.title}
        </h1>
      </div>
    );
  });
  return (
    <div>
      <section>
        <div className="p-5 sm:px-16 sm:p-8 text-center leading-5 sm:leading-8">
          <p className="font-Avenir-light text-md sm:text-xl">
            Xenia Mobi is an Android billing software designed for restaurants,
            cafes, and van sale businesses. It is a lightweight, user-friendly
            application that can be installed on a tablet or mobile device and
            used to manage sales, inventory, and customer orders.
          </p>
        </div>
      </section>
      <section>
        <div className="home-content-title py-5 mb-5">
          <ScrollAnimation
            animateIn="bounceInLeft"
            animateOnce={true}
            duration={1}
          >
            <h2 className="font-josefin text-[35px]">Business Solutions</h2>
          </ScrollAnimation>
          <p className="font-Avenir-light px-5">
            Elevate Your Business with Our Comprehensive Software Solutions for
            Restaurants, Cafes, and Van Sales Distribution etc.
          </p>
        </div>
        <div className="select-none py-5 px-4 md:px-20 lg:px-30 xl:px-60 grid grid-cols-1 sm:grid-cols-2 gap-2 md:gap-5 text-white">
          <div className="relative overflow-hidden h-fit">
            <img
              className="rounded-md w-full hidden sm:block transition duration-300 ease-in-out hover:scale-110"
              src={Restaurant}
              alt="restaurant_image"
            />
            <img
              className="rounded-md w-full block sm:hidden transition duration-300 ease-in-out hover:scale-110"
              src={Restaurant2}
              alt="restaurant_image"
            />
            <div className="absolute p-[5px] sm:p-2 md:p-5 top-0">
              <span className="font-Gotham-bold max-[410px]:text-[20px] text-[30px] sm:text-xl lg:text-4xl">
                Restaurant
              </span>{" "}
              <br />
              <span className="font-Gotham-light font-thin tracking-widest text-[15px] sm:text-base">
                Solutions
              </span>
              <p></p>
            </div>
            <p className="font-Gotham-light absolute bottom-0 p-2 md:p-5 max-[410px]:leading-[15px] max-sm:leading-[25px] max-[410px]:text-[15px] text-[15px] sm:text-lg md:text-xs lg:text-sm font-thin">
              Maximize Efficiency and Delight Customers with Our Innovative
              Restaurant Software Solution
            </p>
          </div>
          <div className="grid grid-rows-2 gap-2 md:gap-4">
            <div className="relative overflow-hidden h-fit">
              <img
                className="rounded-md w-full transition duration-300 ease-in-out hover:scale-110"
                src={Bakkery}
                alt="Bakkery_image"
              />{" "}
              <div className="absolute p-[5px] md:p-5 top-0">
                <p className="font-Gotham-bold max-[410px]:text-[20px] text-[30px] sm:text-xl lg:text-4xl">
                  Bakery
                </p>
                <span className="font-Gotham-light font-thin tracking-widest text-[15px] sm:text-base">
                  Solutions
                </span>
                <p></p>
              </div>
              <p className="font-Gotham-light absolute bottom-0 p-2 md:p-5 max-[410px]:leading-[15px] max-sm:leading-[25px] max-[410px]:text-[15px] text-[15px] sm:text-lg md:text-xs lg:text-sm font-thin">
                Take Your Bakery Business to the Next Level with{" "}
                <br className="hidden md:block" />
                Our Customizable Software Solution
              </p>
            </div>
            <div className="relative overflow-hidden w-full h-fit">
              <img
                className="w-full rounded-md transition duration-300 ease-in-out hover:scale-110"
                src={VanSale}
                alt="van_sale_image"
              />
              <div className="absolute p-[5px] md:p-5 top-0">
                <p className="font-Gotham-bold max-[410px]:text-[20px] text-[30px] sm:text-xl lg:text-4xl">
                  Van Sales
                </p>
                <span className="font-Gotham-light font-thin tracking-widest text-[15px]  sm:text-base">
                  Solutions
                </span>
                <p></p>
              </div>
              <p className="font-Gotham-light absolute bottom-0 p-2 md:p-5 max-[410px]:leading-[15px] max-sm:leading-[25px] max-[410px]:text-[15px] text-[15px] sm:text-lg md:text-xs lg:text-sm font-thin">
                Revolutionize Your Van Sales Operations with{" "}
                <br className="hidden md:block" /> Our Powerful Mobile Software
                Solution
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-content-title py-5 my-5">
        <div>
          <h2 className="font-josefin text-[35px]">Other Business Solutions</h2>
          <p className="font-Avenir-light px-5">
            Unlock Your Business Potential with Our Innovative Software
            Solutions for Any Industry.
          </p>
        </div>
        <div className="py-5 px-5 sm:px-16 xl:px-60 hidden sm:block">
          <HomeCarousel />
        </div>
        <div className="py-5 px-2 block sm:hidden">
          <div className="grid grid-cols-2">{serviceDetails}</div>
        </div>
      </section>
    </div>
  );
};
