import "./Feature.scss";
import { LinkLocation } from "../../Components/LinkLocation";
// import FeatureBanner from "../../assets/feature-banner.webp";
import Logo from "../../assets/logo.png";
import PhoneImage1 from "../../assets/phone_1.png";
import PhoneImage2 from "../../assets/phone_2.png";
import LaptopImage from "../../assets/laptop_1.png";
import Android from "../../assets/android.png";
import IOS from "../../assets/ios.png";
import { ButtonComponent } from "../../Components/Button";
import {
  ApplicationFeature,
  CloudFeatures,
  ReportFeatures,
} from "./FeatureList";
import { useState } from "react";

const Feature = () => {
  const [applicationFeature, setApplicationFeature] = useState(false);
  const [cloudFeature, setCloudFeature] = useState(false);
  const [reportFeature, setReportFeature] = useState(false);
  const defaultButton =
    "bg-gradient-to-b from-[#C981FC] to-[#71C0FF] text-white";
  const selectedButton =
    "bg-white text-[#C981FC] border-2 border-[#C981FC] text-[#C981FC]";
  const defaultButtonStyle = {
    borderRadius: "100px",
    justifyContent: "start",
    color: "white",
    borderColor: "white",
    alignItems: "flex-start",
    textTransform: "none",
    fontFamily: "Montserrat",
  };
  const selectedButtonStyle = {
    backgroundColor: "white",
    borderRadius: "100px",
    justifyContent: "start",
    alignItems: "flex-start",
    borderWidth: "2px",
    borderColor: "#C981FC",
    color: "#C981FC",
    textTransform: "none",
    fontFamily: "Montserrat",
  };

  return (
    <div>
      {/* <div className="select-none">
        <img src={FeatureBanner} alt="banner_image" className="w-full" />
        <div className="feature_location_title font-monteserrat font-thin">
          Home / Features
        </div>
      </div> */}
      <img src="/images/Features.jpg" alt="banner_image" className="w-full" />
      <section className="max-w-[1150px] mx-auto">
        <div className="px-4 py-5 sm:py-10">
          <img
            src={Logo}
            alt="logo"
            className="w-[120px] sm:w-[250px] m-auto lg:m-0 select-none"
          />
          <p className="font-josefin text-3xl sm:text-4xl py-4 text-center lg:text-start">
            Next Gen Android Billing Software
          </p>
          <p className="font-Avenir-book text-lg tracking-wider text-justify leading-8">
            Xenia Mobi is a Revolution in Android Billing & Inventory
            Management. Xenia Mobi manage your products, customers, vendors &
            stock in a smart way and can maintain your van & multiple sale
            outlets simultaneously. Xenia Mobi supports pos devices with inbuilt
            printer, android phones or tablets with additional Bluetooth
            printer.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 py-5">
            <div className="block md:hidden select-none">
              <img
                className="w-full m-auto"
                src={PhoneImage1}
                alt="image_of_a_mobile"
              />
            </div>
            <div className="relative">
              <div className="ml-0 md:ml-16 mt-6 md:mt-12">
                <ul className="leading-10 md:leading-[3.5rem] font-josefin font-medium text-[18px] md:text-[26px]">
                  <li className="flex gap-4 items-center mr-2 md:mr-0">
                    <img
                      className="w-4"
                      src="/images/legend/list-arrow.png"
                      alt="arrow"
                    />
                    POS Billing
                  </li>
                  <li className="flex gap-4 items-center mr-2 md:mr-0">
                    <img
                      className="w-4"
                      src="/images/legend/list-arrow.png"
                      alt="arrow"
                    />
                    Sales Order
                  </li>
                  <li className="flex gap-4 items-center mr-2 md:mr-0">
                    <img
                      className="w-4"
                      src="/images/legend/list-arrow.png"
                      alt="arrow"
                    />
                    Receipt
                  </li>
                  <li className="flex gap-4 items-center mr-2 md:mr-0">
                    <img
                      className="w-4"
                      src="/images/legend/list-arrow.png"
                      alt="arrow"
                    />
                    Sales Reports
                  </li>
                  <li className="flex gap-4 items-center mr-2 md:mr-0">
                    <img
                      className="w-4"
                      src="/images/legend/list-arrow.png"
                      alt="arrow"
                    />
                    Purchase Entry
                  </li>
                </ul>
              </div>
              <div className="ml-0 md:ml-16 m-auto md:m-0 md:absolute bottom-0">
                <ButtonComponent
                  onClick={(e) => setApplicationFeature(!applicationFeature)}
                  buttonStyle={
                    applicationFeature
                      ? selectedButtonStyle
                      : defaultButtonStyle
                  }
                  buttonClassName={`${
                    applicationFeature ? selectedButton : defaultButton
                  }`}
                  className={`${
                    applicationFeature
                      ? "rotate-180 text-[#C981FC]"
                      : "rotate-0 text-white"
                  }`}
                >
                  View {applicationFeature ? "Less" : "More"}
                </ButtonComponent>
              </div>
            </div>
            <div className="hidden md:block select-none">
              <img
                className="w-[90%] m-auto"
                src={PhoneImage1}
                alt="image_of_a_mobile"
              />
            </div>
          </div>
        </div>
        <div
          className={`pt-0 overflow-hidden transition-[max-height] duration-500 ease-in ${
            applicationFeature ? "max-h-[2000px]" : "max-h-0"
          }`}
        >
          {applicationFeature ? <ApplicationFeature /> : null}
        </div>
      </section>

      <section className="gray-background-content">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-4 py-10 max-w-[1150px] mx-auto">
          <div className="hidden md:block select-none">
            <img
              className="w-[90%]"
              src={LaptopImage}
              alt="image_of_a_laptop"
            />
          </div>
          <div className="relative md:flex md:flex-col md:justify-center">
            <h2 className="font-josefin text-2xl font-semibold">Cloud</h2>
            <LinkLocation to={"https://pos.xeniamobi.com"} newTab>
              <p className="link-text-style">(pos.xeniamobi.com)</p>
            </LinkLocation>
            <div className="block md:hidden select-none">
              <img
                className="w-96 m-auto"
                src={LaptopImage}
                alt="image_of_a_laptop"
              />
            </div>
            <p className="font-Avenir-book text-lg tracking-wider text-justify leading-8 pt-8 pb-0 sm:py-8">
              Xenia mobi provides secure & safe Admin panel on cloud. Xenia Mobi
              will store data from multiple stores and locations.
            </p>
            <div className="m-auto md:m-0 md:absolute bottom-0 md:right-0">
              <ButtonComponent
                onClick={(e) => setCloudFeature(!cloudFeature)}
                buttonStyle={
                  cloudFeature ? selectedButtonStyle : defaultButtonStyle
                }
                buttonClassName={`${
                  cloudFeature ? selectedButton : defaultButton
                }`}
                className={`${
                  cloudFeature
                    ? "rotate-180 text-[#C981FC]"
                    : "rotate-0 text-white"
                }`}
              >
                View {cloudFeature ? "Less" : "More"}
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div
          className={`pt-0 overflow-hidden transition-[max-height] duration-500 ease-in ${
            cloudFeature ? "max-h-[2000px]" : "max-h-0"
          }`}
        >
          {cloudFeature ? <CloudFeatures /> : null}
        </div>
      </section>

      <section>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-4 py-5 sm:py-10 max-w-[1150px] mx-auto">
          <div className="relative md:flex md:flex-col md:justify-center">
            <h2 className="font-josefin text-2xl font-semibold">
              Owner Report App
            </h2>
            <div className="flex gap-5">
              <img
                className="w-12 h-12 select-none"
                src={Android}
                alt="android"
              />
              <img className="w-16 h-10 mt-1 select-none" src={IOS} alt="ios" />
            </div>
            <div className="block md:hidden select-none">
              <img
                className="w-full m-auto"
                src={PhoneImage2}
                alt="image_of_a_mobile"
              />
            </div>
            <p className="font-Avenir-book text-lg tracking-wider text-justify leading-8 py-4">
              Your Business is your entity. Xenia Mobi provides you precise
              sales monitoring and effective sales analysis to enhance your
              ongoing business.
            </p>
            <div className="m-auto md:m-0 md:absolute bottom-0">
              <ButtonComponent
                onClick={(e) => setReportFeature(!reportFeature)}
                buttonStyle={
                  reportFeature ? selectedButtonStyle : defaultButtonStyle
                }
                buttonClassName={`${
                  reportFeature ? selectedButton : defaultButton
                }`}
                className={`${
                  reportFeature
                    ? "rotate-180 text-[#C981FC]"
                    : "rotate-0 text-white"
                }`}
              >
                View {reportFeature ? "Less" : "More"}
              </ButtonComponent>
            </div>
          </div>
          <div className="hidden md:block select-none">
            <img
              className="w-[80%]"
              src={PhoneImage2}
              alt="image_of_a_mobile"
            />
          </div>
        </div>
        <div
          className={`pt-0 overflow-hidden transition-[max-height] transform-all duration-500 ease-in ${
            reportFeature ? "max-h-[1000px]" : "max-h-0"
          }`}
        >
          {reportFeature ? <ReportFeatures /> : null}
        </div>
      </section>
    </div>
  );
};
export default Feature;
