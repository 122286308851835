import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { RouteNavigation } from "../../Routes";
import { emailService, GetAllCountry, GetStateByCountry } from "../../lib/api";
// import FeatureBanner from "../../assets/feature-banner.webp";
import { IoIosSend } from "react-icons/io";
import { IoMdApps } from "react-icons/io";
import { MdOutlineInventory2 } from "react-icons/md";
import { MdPayment } from "react-icons/md";
import { MdOutlinePayments } from "react-icons/md";
import { MdPhonelinkSetup } from "react-icons/md";
import { MdInventory2 } from "react-icons/md";

const AboutUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [country, setCountry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateByCountry, setStateByCountry] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const GetCountry = await GetAllCountry();
        setCountry(GetCountry?.data);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchCountryState = async () => {
      if (selectedCountry) {
        try {
          const GetState = await GetStateByCountry(selectedCountry);
          setStateByCountry(GetState?.data);
        } catch (error) {
          console.error("Error fetching country state:", error);
        }
      }
    };

    fetchCountryState();
  }, [selectedCountry]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const dataResponse = await emailService(data);
    if (dataResponse.status === 200) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000);
      setIsChecked(false);
      setIsLoading(false);
      reset();
    } else {
      setIsLoading(false);
      reset();
    }
  };

  return (
    <div>
      {/* <div className="select-none">
        <img src={FeatureBanner} alt="banner_image" className="w-full" />
        <div className="feature_location_title font-monteserrat font-thin">
          Home / About Us
        </div>
      </div> */}
      <img src="/images/About-Us.jpg" alt="banner_image" className="w-full" />
      <div className="max-w-[1150px] mx-auto p-4 sm:px-10 md:px-20">
        <h2 className="font-josefin text-3xl sm:text-4xl py-6 text-center lg:text-start">
          About Us
        </h2>
        <p className="font-Avenir-book text-lg tracking-wider text-justify leading-8 pb-4">
          Xenia Mobi is a Revolution in Android Billing & Inventory Management.
          Xenia Mobi manage your products, customers, vendors & stock in a smart
          way and can maintain your van & multiple sale outlets simultaneously.
          Xenia Mobi supports pos devices with inbuilt printer, android phones
          or tablets with additional Bluetooth printer.
        </p>
        <Link
          to={RouteNavigation.contact}
          className="bg-[#3362CB] px-6 py-2 text-white rounded-md text-sm mt-5 sm:my-5"
        >
          Contact Us
        </Link>
      </div>
      <div className="max-w-[1150px] mx-auto p-4">
        <h2 className="font-josefin font-light">ABOUT</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 gap-x-16">
          <div>
            <h2 className="font-poppins font-semibold text-4xl py-2">
              Smart Billing Solutions Everywhere
            </h2>
            <p className="font-Avenir-book text-lg tracking-wider text-justify leading-8">
              Xenia Mobi is the perfect Android billing solution for businesses
              such as restaurants, cafes, and van sales. Featuring a sleek,
              lightweight design and an intuitive interface, it simplifies
              managing sales, inventory, and orders quickly and efficiently.
              Xenia Mobi streamlines operations by providing real-time inventory
              updates,seamless payment integrations,and effortless order
              processing,
            </p>
            <span className="font-Avenir-book text-lg tracking-wider text-justify leading-8 block lg:hidden">
              making it the perfect tool for businesses on the go. Whether
              you're managing a café or handling mobile sales, it ensures
              billing efficiency and operational excellence wherever you are.
              With Xenia Mobi, you can boost customer service, enhance business
              performance, and stay on top of sales and stock, all from your
              mobile device.
            </span>
          </div>
          <div className="flex flex-col justify-center gap-3">
            <div className="flex gap-x-5 items-center pb-2">
              <div className="bg-[#1F448E] p-3 rounded-full text-white">
                <IoMdApps size="30px" />
              </div>
              <div>
                <h2 className="font-poppins font-medium text-lg uppercase">
                  Applications
                </h2>
                <p className="font-Avenir-book text-justify">
                  Xenia Mobi is designed for restaurants, cafes, van sale
                  businesses, etc.
                </p>
              </div>
            </div>
            <div className="flex gap-x-5 items-center pb-2">
              <div className="bg-[#1F448E] p-3 rounded-full text-white">
                <MdOutlineInventory2 size="30px" />
              </div>
              <div>
                <h2 className="font-poppins font-medium text-lg">
                  Inventory Management
                </h2>
                <p className="font-Avenir-book text-justify">
                  Easily track and manage stock with real-time updates.
                </p>
              </div>
            </div>
            <div className="flex gap-x-5 items-center pb-2">
              <div className="bg-[#1F448E] p-3 rounded-full text-white">
                <MdPayment size="30px" />
              </div>
              <div>
                <h2 className="font-poppins font-medium text-lg">
                  Payment Integration
                </h2>
                <p className="font-Avenir-book text-justify">
                  Supports UPI and card swipe machines for hassle-free
                  transactions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 py-5 items-end">
          <div>
            <img
              src="/images/about_01.jpg"
              alt="dummy_image"
              className="rounded-xl"
            />
          </div>
          <div>
            <p className="font-Avenir-book text-lg tracking-wider text-justify leading-8 hidden lg:block">
              making it the perfect tool for businesses on the go. Whether
              you're managing a café or handling mobile sales, it ensures
              billing efficiency and operational excellence wherever you are.
              With Xenia Mobi, you can boost customer service, enhance business
              performance, and stay on top of sales and stock, all from your
              mobile device.
            </p>
            <div className="grid grid-cols-2 gap-5">
              <img
                src="/images/about_02.jpg"
                alt="dummy_image"
                className="rounded-xl"
              />
              <img
                src="/images/about_03.jpg"
                alt="dummy_image"
                className="rounded-xl"
              />
            </div>
          </div>
        </div>
        <div className="text-center p-4 sm:px-10 md:px-20 sm:py-10">
          <h2 className="font-poppins font-semibold text-4xl">
            WHY XENIA MOBI
          </h2>
          <p className="font-Avenir-book text-lg tracking-wider leading-8 w-full lg:w-2/3 mx-auto py-5">
            Choose Xenia Mobi for unmatched simplicity, efficiency, and smart
            solutions tailored to your business needs.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-5 gap-x-10">
            <div className="shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] p-10 rounded-xl">
              <div className="bg-[#1F448E] p-3 rounded-full text-white w-fit mx-auto">
                <MdPhonelinkSetup size="30px" />
              </div>
              <p className="pb-4 pt-10 font-Avenir-book text-md tracking-wider leading-8">
                Xenia Mobi&apos;s easy-to-use design lets you manage sales,
                inventory, and orders effortlessly, anytime, anywhere.
              </p>
            </div>
            <div className="shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] p-10 rounded-xl">
              <div className="bg-[#1F448E] p-3 rounded-full text-white w-fit mx-auto">
                <MdInventory2 size="30px" />
              </div>
              <p className="pb-4 pt-10 font-Avenir-book text-md tracking-wider leading-8">
                Track and update your stock in real time, ensuring smooth
                operations and accurate inventory management.
              </p>
            </div>
            <div className="shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] p-10 rounded-xl">
              <div className="bg-[#1F448E] p-3 rounded-full text-white w-fit mx-auto">
                <MdOutlinePayments size="30px" />
              </div>
              <p className="pb-4 pt-10 font-Avenir-book text-md tracking-wider leading-8">
                Accept secure payments through UPI and swipe machines, making
                transactions quick and hassle-free.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-[#EFFAFF]">
        <div className="max-w-[1150px] mx-auto p-4 sm:px-10 md:px-20 sm:py-10">
          <h2 className="font-poppins font-semibold text-4xl">
            Our Ideal Partners
          </h2>
          <p className="font-Avenir-book text-lg tracking-wider leading-8 w-full lg:w-3/4 py-5">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore.
          </p>
          <div>PARTNERS LOGO</div>
        </div>
      </div> */}
      <div className="max-w-[1150px] mx-auto p-4 sm:px-10 md:px-20">
        <h2 className="font-poppins font-semibold text-4xl">Enquire Now</h2>
        <p className="font-Avenir-book text-lg tracking-wider leading-8 w-full lg:w-3/4 py-5">
          We're always here to help! Feel free to reach out to us anytime.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 py-5 sm:py-10">
          <div className="col-span-1 md:col-span-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    {...register("firstName", { required: true })}
                    placeholder="First Name"
                    type="text"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                  />
                  {errors.firstName && (
                    <p className="m-2 text-red-600" role="alert">
                      First name is required
                    </p>
                  )}
                </div>
                <div>
                  <input
                    {...register("lastName", { required: true })}
                    placeholder="Last Name"
                    type="text"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                  />
                  {errors.lastName && (
                    <p className="m-2 text-red-600" role="alert">
                      Last name is required
                    </p>
                  )}
                </div>
                <div>
                  <input
                    {...register("email", { required: true })}
                    placeholder="Email"
                    type="email"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                  />
                  {errors.email && (
                    <p className="m-2 text-red-600" role="alert">
                      Email name is required
                    </p>
                  )}
                </div>
                <div>
                  <input
                    {...register("number", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Please enter a valid phone number",
                      },
                    })}
                    placeholder="Phone Number"
                    type="text"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                  />
                  {errors.number && (
                    <p className="m-2 text-red-600">{errors.number.message}</p>
                  )}
                </div>
                <div>
                  <select
                    {...register("country", {
                      required: true,
                      validate: (value) => value !== "0",
                    })}
                    defaultValue="0"
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    placeholder="Country"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                  >
                    <option value="0" disabled>
                      Country
                    </option>
                    {country?.map((countryData) => (
                      <option value={countryData?.countryName}>
                        {countryData?.countryName}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <p className="m-2 text-red-600" role="alert">
                      Country is required
                    </p>
                  )}
                </div>
                <div>
                  <select
                    {...register("state", {
                      required: true,
                      validate: (value) => value !== "0",
                    })}
                    defaultValue="0"
                    placeholder="State"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                  >
                    <option value="0" disabled>
                      State
                    </option>
                    {stateByCountry?.map((stateData) => (
                      <option value={stateData}>{stateData}</option>
                    ))}
                  </select>
                  {errors.state && (
                    <p className="m-2 text-red-600" role="alert">
                      State is required
                    </p>
                  )}
                </div>
                <div className="col-span-2">
                  <input
                    {...register("company", { required: true })}
                    placeholder="Company Name"
                    type="text"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                  />
                  {errors.company && (
                    <p className="m-2 text-red-600" role="alert">
                      Company name is required
                    </p>
                  )}
                </div>
                <div className="col-span-2">
                  <select
                    {...register("subject", {
                      required: true,
                      validate: (value) => value !== "0",
                    })}
                    defaultValue="0"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                  >
                    <option value="0" disabled>
                      Solutions
                    </option>
                    <option value="Restaurant">Restaurant</option>
                    <option value="Bakery">Bakery</option>
                    <option value="Van Sales">Van Sales</option>
                    <option value="Salon">Salon</option>
                    <option value="Fuel Outlet">Fuel Outlet</option>
                    <option value="Vegetable Shop">Vegetable Shop</option>
                    <option value="Retail Shop">Retail Shop</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.subject && (
                    <p className="m-2 text-red-600" role="alert">
                      Please select a product
                    </p>
                  )}
                </div>
                <div className="col-span-2">
                  <textarea
                    {...register("message", {
                      required: "Message your enquiry",
                      minLength: { value: 20, message: "Message too small" },
                    })}
                    placeholder="Enter Your Message"
                    className="bg-[#F9FCFF] border border-[#3362CB] rounded-[16px] p-4 focus:outline-none w-full"
                    rows={5}
                  />
                  {errors.message && (
                    <p className="m-2 text-red-600">{errors.message.message}</p>
                  )}
                </div>
                <div className="col-span-2 flex gap-2 items-center">
                  <input
                    type="checkbox"
                    onClick={() => setIsChecked(!isChecked)}
                  />
                  <span>
                    By Selecting this you agree to our{" "}
                    <Link
                      className="underline text-blue-600"
                      to={RouteNavigation.termsAndConditions}
                    >
                      Terms & Conditions
                    </Link>
                  </span>
                </div>
                <button
                  disabled={!isChecked || isLoading}
                  className="bg-[#3362CB] text-white py-3 rounded-xl col-span-2 sm:col-span-1 flex items-center justify-center gap-2"
                >
                  <IoIosSend size="30px" />
                  Submit
                </button>
              </div>
            </form>
            <div className="mt-2">
              {showAlert && (
                <Alert severity="success">
                  Your request has been successfully processed.
                </Alert>
              )}
            </div>
          </div>
          <div className="col-span-1 content-center hidden md:block">
            <img
              src="/images/enquiry_img.svg"
              alt="a girl vector"
              className="w-3/4 m-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
