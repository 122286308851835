import { HomeTopSection } from "./HomeTopSection";
import { HomeContent } from "./HomeContent";
import Enquiry from "./Enquiry";

const Home = () => {
  return (
    <header>
      <HomeTopSection />
      <HomeContent />
      <Enquiry />
    </header>
  );
};

export default Home;
