function TermsAndConditions() {
  return (
    <div className="max-w-[1024px] m-auto py-6">
      <h2 className="text-3xl font-bold">Xenia Mobi Terms and Conditions</h2>
      <p className="text-justify my-2">
        Welcome to Xenia Mobi's website inquiry form. Before you proceed, please
        take a moment to review the following terms and conditions governing the
        use of this form.
      </p>
      <ol>
        <li>
          <p className="text-xl font-semibold">1. Purpose of the Form</p>
          <p className="text-justify ml-5 mb-2">
            Our inquiry form is designed to facilitate legitimate inquiries
            regarding our services and offerings at Xenia Mobi. Any misuse of
            this form for spamming, solicitation, or unauthorized purposes is
            strictly prohibited.
          </p>
        </li>
        <li>
          <p className="text-xl font-semibold">2. Accuracy of Information</p>
          <p className="text-justify ml-5 mb-2">
            It is important to provide accurate and up-to-date information when
            filling out the form. Xenia Mobi is not liable for any consequences
            arising from the provision of false or inaccurate information.
          </p>
        </li>
        <li>
          <p className="text-xl font-semibold">3. Privacy and Data Security</p>
          <p className="text-justify ml-5 mb-2">
            We prioritize the privacy and security of your information. All
            personal data provided, including email addresses, phone numbers,
            and IP addresses, will be handled in accordance with our Privacy
            Policy.
          </p>
        </li>
        <li>
          <p className="text-xl font-semibold">4. Non-Disclosure Agreement</p>
          <p className="text-justify ml-5 mb-2">
            By submitting information through this form, you agree that Xenia
            Mobi may use the details provided to respond to inquiries, provide
            relevant information, and improve our services. However, we will not
            disclose or sell this information to third parties without explicit
            consent, except as required by law.
          </p>
        </li>
        <li>
          <p className="text-xl font-semibold">5. Communication Consent</p>
          <p className="text-justify ml-5 mb-2">
            By submitting information through this form, you consent to be
            contacted by Xenia Mobi representatives via the provided email
            address or phone number for the purpose of responding to inquiries
            and providing relevant information. You may opt-out of communication
            at any time by contacting us directly.
          </p>
        </li>
        <li>
          <p className="text-xl font-semibold">6. Acceptance of Terms</p>
          <p className="text-justify ml-5 mb-2">
            By submitting information through the Xenia Mobi website inquiry
            form, you acknowledge that you have read, understood, and agreed to
            abide by these Terms and Conditions.
          </p>
        </li>
        <li>
          <p className="text-xl font-semibold">7. Changes to Terms</p>
          <p className="text-justify ml-5 mb-2">
            Xenia Mobi reserves the right to update or modify these Terms and
            Conditions at any time without prior notice. Please review this page
            periodically for any changes. Your continued use of the inquiry form
            after the posting of changes constitutes acceptance of those
            changes. The terms and conditions last updated on <b>23/12/2024</b>.
          </p>
        </li>
      </ol>
      <p className="mt-5 text-center font-semibold italic">
        If you have any questions or concerns regarding these Terms and
        Conditions, please contact us for clarification before submitting any
        information through the inquiry form. Thank you for choosing Xenia Mobi.
      </p>
    </div>
  );
}

export default TermsAndConditions;
