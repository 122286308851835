import Solution from "./solutions.json";
import { useParams } from "react-router-dom";

function Solutions() {
  const location = useParams();
  const FilterSolution = Solution.filter(
    (solution) => solution.link === location.id
  );
  const CurrentSolution = FilterSolution[0];
  return (
    <div className="pb-10">
      <div className="relative text-white">
        <img
          className="w-full min-h-[320px] object-cover"
          src={CurrentSolution.banner}
          alt="banner"
        />
        <div className="absolute top-10 sm:top-1/4 lg:top-1/2 left-5 sm:left-10 max-w-3xl">
          <h1 className="font-josefin text-3xl md:text-5xl">
            {CurrentSolution.title}
          </h1>
          <p className="font-Avenir-book leading-8 py-5 text-sm sm:text-base">
            {CurrentSolution.description}
          </p>
          <button className="font-Avenir-book bg-[#F48527] px-5 py-2 rounded-lg text-sm sm:text-base">
            Get Free Demo
          </button>
        </div>
      </div>
      <div className="bg-[#EDF6FF] mt-5">
        <div className="max-w-[1250px] mx-auto text-center py-10">
          <div
            className="max-w-2xl text-lg sm:text-2xl font-medium font-poppins leading-8 mx-auto capitalize"
            dangerouslySetInnerHTML={{ __html: CurrentSolution.subTitle }}
          />
          <p className="max-w-2xl font-Avenir-book mx-auto mt-4 leading-8">
            {CurrentSolution.subDescription}
          </p>
        </div>
      </div>
      {CurrentSolution?.section?.map((solutionData) => (
        <div
          className={`${
            solutionData.isBackground ? "bg-[#F6F6FE]" : ""
          } pt-5 sm:pt-10`}
        >
          <div className="max-w-[1150px] mx-auto text-center p-4">
            <p className="font-Avenir-book text-3xl sm:text-5xl pb-5 sm:pb-10 capitalize">
              {solutionData.title}
            </p>
            <div
              className={`${
                solutionData.isSingleColumn
                  ? "grid grid-cols-1"
                  : "grid grid-cols-1 sm:grid-cols-2"
              } gap-x-14 gap-y-5 items-center`}
            >
              {solutionData.isImage ? (
                <img
                  className={`${
                    solutionData.isImageStart
                      ? "order-1"
                      : solutionData.isSingleColumn
                      ? "order-2"
                      : "order-1 sm:order-2"
                  } w-full m-auto`}
                  src={solutionData.image}
                  alt={solutionData.title}
                />
              ) : (
                <iframe
                  src={solutionData.image}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                  className="w-full rounded-lg min-h-[250px]"
                />
              )}
              <p
                className={`${
                  solutionData.isImageStart ? "order-2" : "order-1"
                } leading-8 sm:leading-10 text-justify font-Avenir-book text-lg`}
              >
                {solutionData.description}
              </p>
            </div>
            {solutionData.secondaryImage !== "" ? (
              <img
                src={solutionData.secondaryImage}
                alt={solutionData.secondaryImage}
                className="py-10"
              />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Solutions;
