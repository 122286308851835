import "./Footer.scss";
import {
  BiSolidEnvelope,
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoYoutube,
  BiLogoWhatsapp,
} from "react-icons/bi";
import { IoIosCall } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import FooterLogo from "../assets/logo.png";
import { LinkLocation } from "./LinkLocation";
import { RouteNavigation } from "../Routes";

const Footer = () => {
  return (
    <>
      <div className="bg-gradient-to-b from-[#f2f7f8] to-[#f2f7f8] text-black px-0 sm:px-0 md:px-8 lg:px-50 xl:px-60 font-nunito">
        <section className="max-w-[1250px] mx-auto body-font">
          <div className="pt-8 pb-4">
            <div className="flex flex-col sm:flex-row items-center justify-between">
              <div className="py-4 pl-4 w-[220px] sm:w-1/3 lg:w-1/3">
                <div className="h-full flex items-start">
                  <LinkLocation to={"mailto:sales@xeniapos.com"}>
                    <div className="rounded-full text-white p-4 footer-logo-background">
                      <BiSolidEnvelope size={"20px"} />
                    </div>
                  </LinkLocation>
                  <div className="ml-4 self-center">
                    <p className="font-Avenir-medium font-semibold text-sm">
                      sales@xeniapos.com
                    </p>
                    <p className="font-Avenir-book text-xs">Send it with us</p>
                  </div>
                </div>
              </div>
              <div className="py-4 pl-4 w-[220px] sm:w-1/3 lg:w-1/3">
                <div className="h-full flex items-start">
                  <LinkLocation to={"tel:+919745738888"}>
                    <div className="rounded-full text-white p-4 footer-logo-background">
                      <IoIosCall size={"20px"} />
                    </div>
                  </LinkLocation>
                  <div className="ml-4 self-center">
                    <p className="font-Avenir-medium font-semibold text-sm">
                      +91 97457 38888
                    </p>
                    <p className="font-Avenir-book text-xs">Call Us Now</p>
                  </div>
                </div>
              </div>
              <div className="py-4 pl-4 w-[220px] sm:w-1/3 lg:w-1/3">
                <div className="h-full flex items-start">
                  <LinkLocation
                    to={
                      "https://www.google.com/maps/place/Xenia+Technologies+%7C+Solutions+Behind+The+Business/@9.9481442,76.3299582,17z/data=!3m1!4b1!4m6!3m5!1s0x3b0873716c51b469:0x1c1e74a04ba6fd56!8m2!3d9.9481389!4d76.3325331!16s%2Fg%2F11h9y5fyq9?entry=ttu"
                    }
                    newTab
                  >
                    <div className="rounded-full text-white p-4 footer-logo-background">
                      <IoLocationSharp size={"20px"} />
                    </div>
                  </LinkLocation>
                  <div className="ml-4 self-center">
                    <p className="font-Avenir-medium font-semibold text-sm">
                      Gowri Arcade, Kochi
                    </p>
                    <p className="font-Avenir-book text-xs">Get Direction</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr className="hidden sm:block my-4 footer-dividing-line" />

        <section className="block sm:hidden text-white px-4 py-6">
          <div className="select-none text-center footer-logo">
            <img src={FooterLogo} alt="logo" />
          </div>
          <div className="flex items-center mt-4 space-x-2 social-media-logo">
            <LinkLocation
              to={"https://www.facebook.com/xeniatechnologies/"}
              newTab
            >
              <BiLogoFacebook
                size={"25px"}
                className="text-primary hover:text-secondary transform-all duration-300"
              />
            </LinkLocation>
            <BiLogoInstagram
              size={"25px"}
              className="text-primary hover:text-secondary transform-all duration-300"
            />
            <LinkLocation
              to={"https://www.linkedin.com/company/xenia-technologies"}
              newTab
            >
              <BiLogoLinkedin
                size={"25px"}
                className="text-primary hover:text-secondary transform-all duration-300"
              />
            </LinkLocation>
            <LinkLocation
              to={"https://www.youtube.com/@xeniatechnologies5649"}
              newTab
            >
              <BiLogoYoutube
                size={"30px"}
                className="text-primary hover:text-secondary transform-all duration-300 mt-1"
              />
            </LinkLocation>
            <LinkLocation
              to={
                "https://api.whatsapp.com/message/7WHRUPBYFWWNA1?text=Xenia%Technologies&autoload=1&app_absent=0"
              }
              newTab
            >
              <BiLogoWhatsapp
                size={"25px"}
                className="text-primary hover:text-secondary transform-all duration-300"
              />
            </LinkLocation>
          </div>
        </section>

        <section className="max-w-[1250px] mx-auto container py-12 px-5 footer-grid-template">
          <div className="flex flex-col sm:flex-row justify-start sm:justify-between">
            <div>
              <span className="text-xl ml-2 sm:ml-0 font-bold tracking-wide">
                Business Solutions
              </span>
              <ul className="font-Avenir-book font-medium ml-2 leading-9 mt-2">
                <li>Restaurant</li>
                <li>Bakery</li>
                <li>Van Sales</li>
                <li>Cake Shop</li>
                <li>Salon</li>
                <li>Juice Parlor</li>
                <li>Fuel Outlets</li>
                <li>Vegetable Shop</li>
                <li>Retail Shops</li>
              </ul>
            </div>
            <div className="mt-6 sm:mt-0">
              <span className="text-xl ml-2 sm:ml-0 font-bold tracking-wide">
                Categories
              </span>
              <ul className="font-Avenir-book font-medium ml-2 leading-9 mt-2">
                <li>
                  <LinkLocation to={RouteNavigation.home}>Home</LinkLocation>
                </li>
                <li>Solutions</li>
                <li>
                  <LinkLocation to={RouteNavigation.feature}>
                    Features
                  </LinkLocation>
                </li>
                <li>About</li>
                <li>Contact Us</li>
                <li>
                  <LinkLocation to={RouteNavigation.privacy}>
                    Privacy Policy
                  </LinkLocation>
                </li>
                <li>
                  <LinkLocation to={RouteNavigation.refund}>
                    Refund Policy
                  </LinkLocation>
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden sm:block">
            <div className="select-none text-center footer-logo m-auto">
              <img src={FooterLogo} alt="logo" />
            </div>
            <div className="flex items-center mt-4 space-x-2 justify-center social-media-logo">
              <LinkLocation
                to={"https://www.facebook.com/xeniatechnologies/"}
                newTab
              >
                <BiLogoFacebook
                  size={"25px"}
                  className="text-primary hover:text-secondary transform-all duration-300"
                />
              </LinkLocation>
              <LinkLocation
                to={
                  "https://instagram.com/xeniatechnologies?igshid=MzNlNGNkZWQ4Mg=="
                }
                newTab
              >
                <BiLogoInstagram
                  size={"25px"}
                  className="text-primary hover:text-secondary transform-all duration-300"
                />
              </LinkLocation>
              <LinkLocation
                to={"https://www.linkedin.com/company/xenia-technologies"}
                newTab
              >
                <BiLogoLinkedin
                  size={"25px"}
                  className="text-primary hover:text-secondary transform-all duration-300"
                />
              </LinkLocation>
              <LinkLocation
                to={"https://www.youtube.com/@xeniatechnologies5649"}
                newTab
              >
                <BiLogoYoutube
                  size={"30px"}
                  className="text-primary hover:text-secondary transform-all duration-300 mt-1"
                />
              </LinkLocation>
              <LinkLocation
                to={
                  "https://api.whatsapp.com/message/7WHRUPBYFWWNA1?text=Xenia%Technologies&autoload=1&app_absent=0"
                }
                newTab
              >
                <BiLogoWhatsapp
                  size={"25px"}
                  className="text-primary hover:text-secondary transform-all duration-300"
                />
              </LinkLocation>
            </div>
          </div>
        </section>

        <section>
          <p className="text-center">
            Copyright 2023 XeniaTechnologies All Rights Reserved (v.3.3)
          </p>
        </section>
      </div>
    </>
  );
};

export default Footer;
