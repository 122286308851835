import "./PageNotFound.scss";
import { LinkLocation } from "../../Components/LinkLocation";
import { RouteNavigation } from "../../Routes";
import Lottie from "react-lottie";
import animationData from "../../JSON/PageNotFoundAnimation.json";

const PageNotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section className="flex items-center h-full py-8 px-16 bg-gray-300">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <Lottie options={defaultOptions} height={200} width={200} />
          <h2 className="my-2 font-extrabold text-6xl">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            Sorry, we couldn't find this page.
          </p>
          <p className="mt-4 mb-8">
            But dont worry, you can find plenty of other things on our homepage.
          </p>
          <div>
            <LinkLocation to={RouteNavigation.home} rel="noopener noreferrer">
              <span className="brk-btn">Back to homepage</span>
            </LinkLocation>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
